import React, { Component } from "react"
import { navigate } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

import { doCreateUserWithEmailAndPassword } from "api/auth"
import { createUser } from "api/db"

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
})

const INITIAL_STATE = {
  companyName: "",
  companyAddress: "",
  companyCity: "",
  companyState: "",
  companyZipCode: "",
  companyTaxID: "",
  userName: "",
  userPosition: "",
  companyPhoneNumber: "",
  userCellNumber: "",
  companyFaxNumber: "",
  natureOfBusiness: "",
  emailAddress: "",
  createdAt: "",
  passwordOne: "",
  passwordTwo: "",
  error: null,
}

class Register extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.setState({
      createdAt: new Date().toString(),
    })
  }

  onSubmit = event => {
    const {
      companyName,
      companyAddress,
      companyCity,
      companyState,
      companyZipCode,
      companyTaxID,
      userName,
      userPosition,
      companyPhoneNumber,
      userCellNumber,
      companyFaxNumber,
      natureOfBusiness,
      emailAddress,
      createdAt,
      passwordOne,
    } = this.state

    doCreateUserWithEmailAndPassword(emailAddress, passwordOne)
      .then(authUser => {
        createUser(
          authUser.user.uid,
          companyName,
          companyAddress,
          companyCity,
          companyState,
          companyZipCode,
          companyTaxID,
          userName,
          userPosition,
          companyPhoneNumber,
          userCellNumber,
          companyFaxNumber,
          natureOfBusiness,
          emailAddress,
          createdAt
        )
          .then(() => {
            this.setState(() => ({ ...INITIAL_STATE }))
            navigate("/")
          })
          .catch(error => {
            this.setState(updateByPropertyName("error", error))
          })
      })
      .catch(error => {
        this.setState(updateByPropertyName("error", error))
      })

    event.preventDefault()
  }

  render() {
    const {
      companyName,
      companyAddress,
      companyCity,
      companyState,
      companyZipCode,
      companyTaxID,
      userName,
      userPosition,
      companyPhoneNumber,
      userCellNumber,
      companyFaxNumber,
      natureOfBusiness,
      emailAddress,
      passwordOne,
      passwordTwo,
      error,
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      userName === "" ||
      emailAddress === "" ||
      companyName === "" ||
      companyAddress === "" ||
      companyCity === "" ||
      companyState === "" ||
      companyZipCode === "" ||
      companyTaxID === "" ||
      userPosition === "" ||
      companyPhoneNumber === "" ||
      userCellNumber === "" ||
      companyFaxNumber === "" ||
      natureOfBusiness === ""

    return (
      <Layout>
        <SEO title="Login | Tick Tock Inc." />
        <section className="ticktockinc-container-small">
          <h1 className="is-size-4 has-text-centered has-text-weight-bold">
            Create an Account{" "}
          </h1>
          <br />
          <form onSubmit={this.onSubmit}>
            <div class="field">
              <label class="label">Company Name</label>

              <p class="control">
                <input
                  value={companyName}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("companyName", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Company Name"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Company Address</label>

              <p class="control">
                <input
                  value={companyAddress}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("companyAddress", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Company Address"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Company City</label>

              <p class="control">
                <input
                  value={companyCity}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("companyCity", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Company City"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Company State</label>

              <p class="control">
                <input
                  value={companyState}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("companyState", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Company State"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Company Zip Code</label>

              <p class="control">
                <input
                  value={companyZipCode}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("companyZipCode", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Company Zip Code"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Company Tax ID</label>

              <p class="control">
                <input
                  value={companyTaxID}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("companyTaxID", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Company Tax ID"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Full Name</label>

              <p class="control">
                <input
                  value={userName}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("userName", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Full Name"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Position</label>

              <p class="control">
                <input
                  value={userPosition}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("userPosition", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Position"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Company Phone</label>

              <p class="control">
                <input
                  value={companyPhoneNumber}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName(
                        "companyPhoneNumber",
                        event.target.value
                      )
                    )
                  }
                  type="tel"
                  placeholder="Company Phone"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Company Fax</label>

              <p class="control">
                <input
                  value={companyFaxNumber}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName(
                        "companyFaxNumber",
                        event.target.value
                      )
                    )
                  }
                  type="tel"
                  placeholder="Company Fax Number"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Cell Number</label>

              <p class="control">
                <input
                  value={userCellNumber}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("userCellNumber", event.target.value)
                    )
                  }
                  type="tel"
                  placeholder="Cell Number"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Nature of Business</label>

              <p class="control">
                <input
                  value={natureOfBusiness}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName(
                        "natureOfBusiness",
                        event.target.value
                      )
                    )
                  }
                  type="text"
                  placeholder="Nature of Business"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Email Address</label>

              <p class="control">
                <input
                  value={emailAddress}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("emailAddress", event.target.value)
                    )
                  }
                  type="text"
                  placeholder="Email Address"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Password</label>

              <p class="control">
                <input
                  value={passwordOne}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("passwordOne", event.target.value)
                    )
                  }
                  type="password"
                  placeholder="Password"
                  className="input"
                />
              </p>
            </div>

            <div class="field">
              <label class="label">Confirm Password</label>

              <p class="control">
                <input
                  value={passwordTwo}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("passwordTwo", event.target.value)
                    )
                  }
                  type="password"
                  placeholder="Confirm Password"
                  className="input"
                />
              </p>
            </div>

            <br />
            <button
              className="button is-info is-rounded"
              onClick={this.onSubmit}
              disabled={isInvalid}
              type="submit"
            >
              Sign Up
            </button>

            {error && <p>{error.message}</p>}
          </form>
        </section>
      </Layout>
    )
  }
}

export default Register
